import React from "react";

import Oportunities from "components/oportunities/Oportunities";

const OportunitiesPage = (props) => {
  //PROPS
  const { bikes } = props;
  //PROPS

  return (
    <main className="main">
      <Oportunities bikes={bikes} />
    </main>
  );
};

export const query = graphql`
  query Oportunidades($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      oportunidades {
        text
        image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default OportunitiesPage;
